/* eslint-disable max-len */
export default {
  errors: {
    pleaseFillField: 'Veuillez remplir ce champ',
    maxLength: 'Limite de caractères dépassée',
    latinLetters: 'Ce champs ne peut contenir que des lettres',
    login: "Votre adresse email ou votre mot de passe n'est pas valide.",
    password: 'Le mot de passe doit contenir au minimum 8 caractères dont une majuscule et un chiffre',
    confirmation: 'Les mots de passe doivent être identiques',
    mail: 'Merci de renseigner une adresse e-mail valide.',
    updateUser: "Une erreur est survenue lors de la mise à jour de l'utilisateur",
    updateAdherent: "Une erreur est survenue lors de la mise à jour de l'adhérent",
    updateAgency: "Une erreur est survenue lors de la mise à jour de l'agence",
    updatePrestation: 'Une erreur est survenue lors de la mise à jour de la prestation',
    updateAnnouncement: 'Une erreur est survenue lors de la mise à jour de l\'annonce',
    answerAnnouncement: 'Une erreur est survenue lors de la soumission de la demande',
    updateContent: 'Une erreur est survenue lors de la mise à jour du contenu',
    updateNetworkNews: "Une erreur est survenue lors de la mise à jour de l'actu",
    updateDoc: 'Une erreur est survenue lors de la mise à jour du document',
    updateThread: 'Une erreur est survenue lors de la mise à jour de la conversation',
    updatePost: 'Une erreur est survenue lors de la mise à jour de la réponse',
    updateDemand: 'Une erreur est survenue lors de la mise à jour de la demande',
    updateJobOffer: 'Une erreur est survenue lors de la mise à jour de l\'offre',
    createUser: "Une erreur est survenue lors de la création de l'utilisateur",
    createAdherent: "Une erreur est survenue lors de la création de l'adhérent",
    createAgency: "Une erreur est survenue lors de la création de l'agence",
    createContent: 'Une erreur est survenue lors de la création du contenu',
    createPrestation: 'Une erreur est survenue lors de la création de la prestation',
    createAnnouncement: 'Une erreur est survenue lors de la création de l\'annonce',
    createNetworkNews: "Une erreur est survenue lors de la création de l'actu",
    createThread: 'Une erreur est survenue lors de la création de la conversation',
    createApp: 'Une erreur est survenue lors de la création de l\'application',
    updateApp: 'Une erreur est survenue lors de la mise à jour de l\'application',
    deleteApp: 'Une erreur est survenue lors de la suppression de l\'application',
    createDoc: 'Une erreur est survenue lors de la création du document',
    createPost: 'Une erreur est survenue lors de la création de la réponse',
    createJobOffer: 'Une erreur est survenue lors de la création de l\'offre',
    getUser: 'Une erreur est survenue lors du chargement des utilisateurs',
    getJobTypes: 'Une erreur est survenue lors du chargement des types d\'offres',
    getJobOffers: 'Une erreur est survenue lors du chargement des offres d\'emploi',
    getAdherent: 'Une erreur est survenue lors du chargement des adhérents',
    getAgencies: 'Une erreur est survenue lors du chargement des agences',
    getNotifications: 'Une erreur est survenue lors du chargement des notifications',
    updateNotifications: 'Une erreur est survenue lors de la mise à jour des notifications',
    deleteNotifications: 'Une erreur est survenue lors de la suppression des notifications',
    getActualities: 'Une erreur est survenue lors du chargement des actualités',
    getActuality: "Une erreur est survenue lors du chargement de l'actualité",
    getQuestions: 'Une erreur est survenue lors du chargement de la faq',
    getMonthlyResults: 'Une erreur est survenue lors du chargement des chiffres clés',
    getDoc: 'Une erreur est survenue lors du chargement du document',
    getCategories: 'Une erreur est survenue lors du chargement des catégories',
    getTypes: 'Une erreur est survenue lors du chargement des types',
    getRegion: 'Une erreur est survenue lors du chargement des régions',
    getContents: 'Une erreur est survenue lors du chargement des contenus',
    deleteUser: 'Une erreur est survenue lors de l\'archivage de l\'utilisateur',
    getAnnouncements: 'Une erreur est survenue lors du chargement des annonces',
    getApplications: 'Une erreur est survenue lors du chargement des applications',
    getThreads: 'Une erreur est survenue lors du chargement des conversations',
    getAnnouncementsEnergyTypes: 'Une erreur est survenue lors du chargement des types d\'énergies',
    getForumThemes: 'Une erreur est survenue lors du chargement des thèmes du forum',
    getThread: 'Une erreur est survenue lors du chargement de la conversation',
    getAnswers: 'Une erreur est survenue lors du chargement des réponses',
    sendConnectionLink: "Une erreur est survenue lors de l'envoi du mail de première connexion",
    userLoading: "Une erreur est survenue lors du chargement des données de l'utilisateur",
    agencyLoading: "Une erreur est survenue lors du chargement des données de l'agence",
    demandsLoading: 'Une erreur est survenue lors du chargement des demandes',
    adherentLoading: "Une erreur est survenue lors du chargement des données de l'adhérent",
    equipmentLoading: 'Une erreur est survenue lors du chargement des données des équipements',
    prestationLoading: 'Une erreur est survenue lors du chargement des données des prestations',
    roleLoading: 'Une erreur est survenue lors du chargement des profils',
    colorLoading: 'Une erreur est survenue lors du chargement des couleurs',
    contentLoading: 'Une erreur est survenue lors du chargement du contenu',
    newsLoading: "Une erreur est survenue lors du chargement de l'actualité",
    prestationTypeLoading: 'Une erreur est survenue lors du chargement des types de prestation',
    setPwd: 'Une erreur est survenue lors de la définition du mot de passe',
    setEmail: 'Une erreur est survenue lors du changement d\'email',
    setPhones: 'Une erreur est survenue lors du changement des numéros de téléphone',
    setEmailNoChange: 'L\'adresse email envoyée est déjà en cours de validité',
    fileUpload: "Une erreur est survenue lors de l'upload du fichier",
    fileDownload: 'Une erreur est survenue lors du téléchargement du fichier',
    deletePrestation: 'Une erreur est survenue lors de la suppression de la prestation',
    deleteNews: 'Une erreur est survenue lors de la suppression de l\'actualité',
    deleteDoc: 'Une erreur est survenue lors de la suppression du document',
    deleteContent: 'Une erreur est survenue lors de la suppression du contenu',
    deleteAnnouncement: 'Une erreur est survenue lors de la suppression de l\'annonce',
    deleteThread: 'Une erreur est survenue lors de la suppression de la conversation',
    deletePost: 'Une erreur est survenue lors de la suppression du message',
    deleteJobOffer: 'Une erreur est survenue lors de l\'offre',
    equipmentDetailsUpdate: "Une erreur est survenue lors de la mise à jour des détails de l'équipement",
    equipmentDetailsLoading: "Une erreur est survenue lors du chargement des détails de l'équipement",
    setZipcodes: "Une erreur est survenue lors de l'enregistrement des codes postaux.",
    spareParts: {
      duplicate_response_forbidden: 'Vous avez déjà répondu à cette annonce',
      self_response_forbidden: 'Vous ne pouvez pas répondre à votre propre annonce',
    },
    sendEmailVerification: "Une erreur est survenue à l'envoi de l'email de vérification",
    sendEmailVerificationNotExist: 'Il semble que vous ne soyez pas client d\'une '
        + 'agence du réseau Axenergie. Merci de renseigner un email fourni à votre agence ou de la contacter.',
    sendEmailVerificationAlreadyExist: 'Un compte client est déjà configuré avec cette adresse email. '
        + 'Vous avez perdu votre mot de passe ? Utilisez la fonctionnalité de mot de passe oublié pour vous connecter.',
    updateMonthlyResult: 'Une erreur est survenue lors de la mise à jour des résultats',
    createMonthlyResult: 'Une erreur est survenue lors de la création des résultats',
    validateQuote: 'Une erreur est survenue lors de la validation du devis',
    refuseQuote: 'Une erreur est survenue lors de la demande de refus du devis',
    getQuotes: 'Une erreur est survenue lors  du chargement de la liste des devis',
    getInvoices: 'Une erreur est survenue lors  du chargement de la liste des factures',
    agencyContracts: 'Une erreur est survenue lors du chargement du contrat',
    agencyContractDocuments: 'Une erreur est survenue lors du chargement des documents du contrat',
    agencyDocumentNotFound: 'Le fichier demandé est introuvable',
    extranetContactInvalid: 'Merci de compléter les informations manquantes.',
    extranetContact: "Une erreur est survenue lors de l'envoi de la demande de contact",
    missingFiguresData: 'Vous devez saisir vos chiffres pour ce mois afin de voir les chiffres du réseau',
    payment: 'Une erreur est survenue lors ddu paiement',
    nothingToPay: 'Aucun montant n\'est renseigné',
    amountTooLow: 'Le montant à payer est trop bas',
    amountTooHigh: 'Le montant à payer est top haut',
    invalidPaymentEmail: 'L\'email de paiement est invalide',
    paymentAlreadyExist: 'La facture a déjà été payée',
  },
  confirmationMessages: {
    deactivatePrestation: 'Êtes-vous sûr de vouloir désactiver cette prestation ? ',
    activatePrestation: 'Activer la prestation ? ',
    deletePrestation: 'Êtes-vous sûr de vouloir supprimer cette prestation ? ',
    deleteUser: 'Êtes-vous sûr de vouloir archiver cet utilisateur ? ',
    deleteAnnouncement: 'Êtes-vous sûr de vouloir supprimer cette annonce ? ',
    deleteApp: 'Êtes-vous sûr de vouloir supprimer cette application ? ',
    deleteNews: 'Êtes-vous sûr de vouloir supprimer cette actualité ? ',
    deleteDoc: 'Êtes-vous sûr de vouloir supprimer ce document ? ',
    deleteThread: 'Êtes-vous sûr de vouloir supprimer cette conversation ? ',
    deletePost: 'Êtes-vous sûr de vouloir supprimer ce message ? ',
    deleteJobOffer: 'Êtes-vous sûr de vouloir supprimer cette offre ? ',
    validateQuote: {
      title: 'Validation du devis',
      message: 'Je confirme avoir pris connaissance du devis et des Conditions générales de vente. Je confirme vouloir lancer les travaux associés.',
    },
    refuseQuote: {
      title: 'Refus du devis',
      message: 'Je confirme avoir pris connaissance du devis et confirme vouloir refuser les travaux associés.',
    },
  },
  states: {
    loading: 'Chargement...',
  },
  success: {
    sendConnectionLink: "Un email a été envoyé à l'utilisateur",
    // eslint-disable-next-line max-len
    sendEmailVerification: "Un email pour activer votre compte vous a été envoyé. Merci de valider le lien d'activation pour finaliser l'accès à votre compte.",
    changeEmailVerification: 'Un email pour valider votre changement vous a été envoyé. '
        + "Merci d'ouvrir le lien qu'il contient pour finaliser la modification.",
    changeEmail: 'Votre changement d\'adresse email de contact sera effectif dès sa prise en compte par votre agence.',
    changeEmailContact: 'La modification de votre adresse email de contact a bien été enregistrée '
        + 'et sera effective dès sa prise en compte par votre agence',
    changePhones: 'La modification de vos numéros de téléphones de contact a bien été enregistrée '
        + 'et sera effective dès sa prise en compte par votre agence',
    deletePrestation: 'La prestation a bien été supprimée',
    deleteNews: 'L\'actualité a bien été supprimée',
    deleteDoc: 'Le document a bien été supprimée',
    deleteContent: 'Le contenu a bien été supprimée',
    deleteAnnouncement: 'Le contenu a bien été supprimée',
    deleteThread: 'La conversation a bien été supprimée',
    deleteJobOffer: 'L\'offre d\'emploi a bien été supprimée',
    deletePost: 'Le message a bien été supprimée',
    updateAgency: "L'agence a été mise à jour avec succès",
    setZipcodes: 'Les codes postaux ont été enregistrés avec succès',
    createPost: 'La réponse a été envoyée avec succès',
    createApp: 'L\'application a été envoyée avec succès',
    updateApp: 'L\'application a été mise à jour avec succès',
    deleteApp: 'L\'application a été supprimée avec succès',
    updatePost: 'La réponse a été modifiée avec succès',
    answerAnnouncement: 'La demande a été envoyée avec succès',
    verifyEmailSuccess: 'Votre adresse email est validée, vous pouvez désormais vous connecter à votre espace',
    updateMonthlyResult: 'Les résultats ont été mis à jour avec succès',
    createMonthlyResult: 'Les résultats ont été envoyés avec succès',
    validateQuote: 'Le devis a été validé avec succès',
    refuseQuote: 'La demande de refus a été envoyée avec succès',
    changePassword: 'Votre mot de passe a été modifié avec succès',
    extranetContact: 'Votre message a bien été envoyée, votre agence vous recontactera dans les plus brefs délais.',
  },
  zipCode: 'Code postal',
  choose: 'Choisir',
  yourAddress: 'Votre adresse',
  yourEquipments: 'Vos équipements',
  myContracts: 'Mes contrats',
  moveOut: 'Nouvelle adresse, vous déménagez ?',
  welcomeUserSpace: 'Bienvenue sur votre espace client',
  welcomeUserSpaceAdmin: 'Bienvenue sur votre intranet Axenergie !',
  password: 'Mot de passe',
  connection: 'Connexion',
  forgotPassword: 'Mot de passe oublié ?',
  forgotPasswordSubtitle: 'Renseignez votre email pour définir un nouveau mot de passe.',
  firstConnection: 'Première connexion ?',
  welcomeFirstConnection: 'Créez votre compte client',
  goBack: 'Retour',
  resetPasswordSuccess:
      'Si votre email existe dans notre système, '
      + 'vous recevrez un message comportant les instructions pour réinitialiser votre mot de passe.',
  resetPassword: 'Réinitialisation du mot de passe',
  goOn: 'Continuer',
  back: 'Retour',
  setPassword: 'Définition du mot de passe',
  confirmation: 'Confirmation',
  setPasswordSuccess: 'Votre mot de passe a bien été créé !',
  users: 'Utilisateurs',
  adherents: 'Adhérents',
  monthlyBrandSelect: 'Marques - ventes',
  monthlyEquipmentSalesSelect: 'Équipement - ventes',
  monthlyEquipmentComissioningsSelect: 'Équipement - mises en service',
  monthlyEquipmentContractsSelect: 'Équipement - nombre de contrats',
  quickLinkRegion: "L'activité de ma région",
  networkBaseDoc: 'La base documentaire du réseau',
  adherentForum: 'Le forum adhérent',
  techForum: 'Le forum technicien',
  administrationForum: 'Le forum administratif',
  quickLinkForum: "L'activité de mon forum",
  quickLinkCA: "L'activité du CA",
  dashboardNews: 'Actualités du réseau',
  dashboardAgenda: 'Agenda du réseau',
  dashboardClients: 'Activités clients',
  dashboardRegion: 'Répertoire région',
  dashboardMonthly: 'Les chiffres clés du réseau',
  seeAll: 'Tout voir',
  monthlyResultLabel: (month) => `Ventes Adhérent (${month})`,
  adherentTotalFigures: (month) => `Somme de toutes les saisies (${month})`,
  networkCompletion: (percentage) => `(${percentage}% du réseau)`,
  handleAdminUsers: 'Gestion des utilisateurs du réseau',
  handleAdherents: 'Gestion des adhérents',
  handleAdherentUsers: "Gestion des utilisateurs de l'intranet",
  myUsers: 'Mes utilisateurs',
  myAdherents: 'Mes adhérents',
  name: 'Nom',
  job: 'Descriptif du poste',
  mail: 'E-mail',
  phone: 'Téléphone',
  role: 'Profil',
  connectionLink: 'Lien de connexion',
  deleteUser: 'Archiver',
  addUser: 'Ajouter un utilisateur',
  orderAdmin: 'Ordonner les utilisateurs',
  addAdherent: 'Ajouter un adhérent',
  addAgency: 'Ajouter une agence',
  selectAgency: 'Sélectionner une agence',
  addContent: 'Ajouter un contenu',
  addNetworkNews: 'Ajouter une actu réseau',
  addDocument: 'Ajouter un document',
  firstname: 'Prénom',
  admin: 'Administrateur',
  admins: 'Administrateurs',
  adminCA: 'Administrateur membre du CA',
  save: 'Enregistrer',
  newUser: 'Nouvel utilisateur',
  newAdherent: 'Nouvel adhérent',
  newAgency: 'Nouvelle agence',
  newPrestation: 'Nouvelle prestation',
  newContent: 'Nouveau contenu',
  newNetworkNews: 'Nouvelle actu du réseau',
  newDoc: 'Nouveau document',
  region: 'Région',
  regions: 'Régions',
  next: 'Suivant',
  previous: 'Précédent',
  adherentName: "Nom de l'adhérent",
  agencies: 'Agences',
  userAdherents: 'Utilisateurs adhérents',
  logMeOut: 'Me déconnecter',
  user: 'Utilisateur',
  agencyDescription: "Descriptif de l'agence",
  interventionZone: "Zone d'intervention",
  agencyLogo: "Logo de l'agence",
  agencyPicture: "Photo de l'agence",
  agencyAdvertising: 'Charger la pub',
  changeAgencyLogo: 'Changer le logo',
  changeAgencyPicture: 'Changer la photo',
  changeAgencyAdvertising: 'Changer la pub',
  googleMyBusinessId: 'Identifiant Google My Business',
  googleMyBusinessUrl: 'URL Google My Business',
  externalLink: 'Bouton de lien externe',
  externalLinkLabel: 'Libellé',
  externalLinkUrl: 'URL',
  manageAdvertising: 'Gestion des publicités extranet clients',
  chooseAvatar: 'Choisir un avatar',
  changeAvatar: "Changer l'avatar",
  noData: 'Aucune donnée',
  networkPrestations: 'Les prestations du réseau',
  myPrestationsAndVisibility: 'Mes prestations et ma visibilité',
  addPrestation: 'Ajouter une prestation',
  prestations: 'Prestations',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  title: 'Titre',
  subtitle: 'Sous-titre',
  argument: 'Description de l\'offre',
  details: 'Details',
  fiveGoodReasonBlock: 'Bloc 5 Bonnes raison de',
  notMandatory: 'Facultatif',
  reason: 'Raison',
  intro: 'Intro',
  illustrationImage: "Image d'illustration",
  changeImage: "Changer l'image",
  color: 'Couleur',
  prestationRequiredForAll: 'Prestation obligatoire pour les agences',
  myAgencyPage: 'Ma page agence',
  agencyPage: 'Page agence',
  myContents: 'Mes contenus',
  myNews: 'Mes actualités',
  agencyPageSubtitle: 'Sous titre pour la page agence',
  agencyPageVideoUrl: 'Vidéo Youtube (URL)',
  agencyPageDescription: 'Description pour la page agence',
  whoAreWe: 'Qui sommes-nous ?',
  logo: 'Logo',
  changeLogo: 'Changer le logo',
  manageMyAdvertisement: 'Gérer ma pub sur le site',
  uploadMyAdvertisement: 'Charger ma pub',
  changeMyAdvertisement: 'Changer ma pub',
  optimalAdImageSize: (width, height) => `Taille optimale de l'image : ${width} x ${height} px`,
  theNetworkNews: 'Les actus du réseau - MDV',
  networkNews: 'Les actus réseau - MDV',
  networkNewsSubtitle: "Retrouvez ici toute l'actualité Axenergie",
  allNews: 'Toutes les actus',
  noResult: 'Aucun résultat',
  noNews: 'Aucune actualité ne correspond à votre recherche',
  noAnnouncement: 'Aucune annonce ne correspond à votre recherche',
  noQuestions: 'Aucune question ne correspond à cette catégorie',
  noDocument: 'Aucun document ne correspond à votre recherche',
  noDemand: 'Aucune demande ne correspond à votre recherche',
  noThread: 'Aucune conversation ne correspond à votre recherche',
  searchKeyWords: 'Recherche par mots-clés',
  contentDetails: 'Détails du contenu',
  delete: 'Supprimer',
  toDownload: 'Les ressources à télécharger',
  category: 'Catégorie',
  newsContent: "Texte de l'actu",
  docContent: 'Texte du document',
  file: 'Fichier',
  changeFile: 'Changer le fichier',
  onlyAdherent: 'Adhérents uniquement',
  everyone: 'Tout le monde',
  receivers: 'Destinataires',
  networkContent: 'Contenus réseau',
  theNetworkContent: 'Les contenus réseau',
  forSavPrestations: 'Pour les prestations de SAV',
  forInstallationPrestations: 'Pour les prestations de remplacement / installation',
  forAdivcePrestations: 'Pour les prestations de conseils et infos',
  prestationTitle: 'Titre des prestations',
  oneArgument: 'Argument',
  myPrestationSubTitle:
      'Gérer ici votre visibilité sur le site Axenergie et les prestations que vous souhaitez proposer',
  from: 'A partir de',
  servedZipCodes: 'Code postaux desservis',
  loadFile: 'Charger un fichier',
  seeZipcodeList: 'Voir la liste des codes postaux',
  myAgency: 'Mon agence',
  myContactDetails: 'Mes coordonnées',
  agencyName: "Nom de l'agence",
  address: 'Adresse',
  zipcode: 'Code postal',
  city: 'Ville',
  openingHours: 'Horaires',
  openingHoursExample: 'ex: Du lundi au vendredi de 9h à 17h',
  phone1LabelExample: 'ex: Téléphone heure ouvrée',
  phone2LabelExample: 'ex: Téléphone soir et week-ends',
  mail1LabelExample: 'ex: Contact',
  mail2LabelExample: 'ex: Comptabilité',
  contactUs: 'Nous joindre',
  contactUsSecondary: 'Contactez nous',
  label: 'Libellé',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  instagram: 'Instagram',
  twitter: 'Twitter',
  legalInfo: 'Informations légales',
  sirenNumber: 'Numéro de SIREN',
  legalOfficer: 'Représentant légal',
  socialReason: 'Raison sociale',
  generalTerms: 'CGV',
  SOSSpareParts: 'SOS pièces détachées',
  SOSSparePartsSubtitle:
      'Demandez une pièce à un collègue, offrez une deuxième vie à vos pièces que vous n\'utiliserez pas',
  expireThe: 'expire le',
  wantIt: 'Je le veux',
  gotIt: "Je l'ai",
  OFFER: 'Offres',
  networkAnnouncements: 'Les annonces du réseau',
  myInProgressAnnouncements: 'Mes annonces en cours',
  expired: 'Expirée',
  markAsread: 'Marquer comme lue',
  markAsUnread: 'Marquer comme non lue',
  markAsOver: 'Marquer comme terminé',
  latitude: 'Latitude',
  longitude: 'Longitude',
  newAnnouncement: 'Nouvelle annonce',
  newApp: 'Nouvelle application',
  newOffer: 'Nouvelle offre',
  newSparePartAnnouncement: 'Nouvelle demande de pièces',
  send: 'Envoyer',
  descriptionText: 'Descriptif',
  announcementTitleInfoDont: 'Vous n\'avez pas besoin de mentionner « Demande » ou « Offre » ici.',
  announcementTitleInfoDo: 'Un bon titre c’est quelques mots pour décrire précisément votre bien.',
  announcementDescriptionInfo:
    'N’oubliez pas de préciser le maximum d’informations lors de votre demande (modèle, marque, numéro de série…)',
  announcementDescriptionRequirements: '130 caractères maximum',
  demandAnnouncementDuration: 'Votre annonce sera visible pendant 1 semaine.',
  offerAnnouncementDuration: 'Votre annonce sera visible pendant 6 mois.',
  docBase: 'Base documentaire',
  general: 'Générale',
  caSpace: 'Espace CA',
  north: 'Nord',
  center: 'Centre',
  west: 'Ouest',
  southWest: 'Sud Ouest',
  southEast: 'Sud Est',
  docBaseSubTitle: 'Retrouvez ici tous les documents Axenergie',
  docType: 'Type de document',
  download: 'Télécharger',
  downloadBill: 'Télécharger la facture',
  the: 'Le',
  subCategory: 'Sous-catégorie',
  shortDescription: 'Courte description',
  forums: 'Forums',
  forum: 'Forum',
  forumSubtitle: 'Echangez avec tous les membres du réseau',
  newThread: 'Nouvelle conversation',
  by: 'par',
  open: 'Ouvert',
  over: 'Terminé',
  lastUpdate: 'Dernière mise à jour',
  answers: 'réponses',
  answer: 'réponse',
  toAnswer: 'Répondre',
  message: 'Message',
  attachFiles: 'Joindre des fichiers',
  attachedFiles: 'Les fichiers joints',
  fileName: 'Nom fichier',
  publish: 'Publier',
  demandOk: 'Demande traitée',
  edit: 'Éditer',
  modifiedThe: 'Modifié le',
  openThread: 'Ouvrir la conversation',
  regionBook: 'Répertoire région',
  regionBookSubtitle: 'Le trombinoscope de tous les adhérents du réseau',
  allRegions: 'Toutes les régions',
  applicationsAxenergie: 'Les applications Axenergie',
  applicationsAxenergieSubTitle: 'Retrouvez ici toutes les applications',
  applications: 'Applications',
  url: 'url',
  urlExample: 'https://www.monurl.com',
  notifications: 'Notifications',
  markAllAsRead: 'Marquer tout comme lu',
  emptyList: 'Vider la liste',
  yourAnnouncement: 'Votre annonce',
  hasExpired: 'a expirée',
  have: 'possède',
  wish: 'souhaite',
  yourSparePart: 'votre pièce détachée',
  contactHim: 'prenez contact avec lui',
  jobOfferTitle: 'Les modèles et offres d\'emploi du réseau',
  jobOfferSubtitle: 'Gérez vos offres d\'emploi dans ce module',
  jobOffers: 'Offres d\'emploi',
  type: 'Type',
  domain: 'Métier',
  publicationDate: 'Date de publication',
  template: 'Modèle',
  defineAsTemplate: 'Définir comme modèle',
  geographicZone: 'Zone géographique',
  demandReceiver: 'Destinataires de la demande',
  mailExample: 'moi@axenergie.eu,autre@contact.fr',
  validityDate: 'Date de validité',
  networkHead: 'Tête de réseau',
  newAnswer: 'Nouvelle réponse',
  newNetworkNewsNotif: 'Nouvelle actualité réseau',
  networkAdherents: 'Adhérents du réseau',
  networkHeads: 'Tête de réseau',
  monthlyResultHeader: 'Publier mes chiffres',
  monthlyResultSelectPlaceholder: 'Mes chiffres du mois',
  verifyEmailLoading: 'Traitement.',
  demandFromSite: 'Demandes en provenance du site',
  invoiceFromSite: 'Paiements en provenance du site',
  quotationFromSite: 'Devis en provenance du site',
  demandPageSubtitle: 'Retrouvez ici toutes les demandes de contact de client en provenance du site et de l\'extranet',
  invoicePageSubtitle: 'Retrouvez ici tous les paiements en provenance du site et de l\'extranet',
  quotationPageSubtitle: 'Retrouvez ici toutes les interactions sur les devis en provenance du site et de l\'extranet',
  demands: 'Demandes',
  quotations: 'Devis',
  invoices: 'Paiements',
  contacts: 'Prises de contact',
  allDemands: 'Toutes les demandes',
  allContacts: 'Toutes les prises de contact',
  allQuotations: 'Tous les devis',
  allInvoices: 'Tous les paiements',
  toHandle: 'A traiter',
  handledPlural: 'Traitées',
  handled: 'Traitée',
  new: 'Nouveau',
  axenergieSolutions: 'AXENERGIE SOLUTIONS',
  demandObject: 'Objet de la demande',
  setAsProcessed: 'Indiquer comme traitée',
  setAsNotProcessed: 'Indiquer comme à traiter',
  demandSentFrom: 'Demande effectuée depuis',
  wantsToReceiveNewsletter: 'Souhaite recevoir CONSEILS & BONS PLANS par mail',
  availibilities: 'Disponibilités',
  morning: 'Matin',
  afternoon: 'Après-midi',
  brandResult: 'Installation / Remplacement',
  equipmentResult: 'Ventes par équipement',
  energyResult: 'Ventes par énergie',
  nbContracts: 'Nombre de contrats (votre portefeuille total de contrat à la fin du mois)',
  newCommissioning: 'Mises en service',
  sav: 'SAV',
  logMeInAs: 'Se connecter en tant que',
  youAreLoggedAs: 'Vous êtes connectés en tant',
  processing: 'Traitement.',
  chooseAgency: 'Sélectionner votre agence et accéder à toutes vos informations',
  contactLabel: 'Une panne, une question ?',
  goToFaq: 'Consulter la FAQ',
  haveBreakdown: 'Je suis en panne',
  quoteValidate: 'Valider le devis',
  quoteRefuse: 'Refuser le devis',
  payInvoice: 'Payer la facture',
  toDoList: (actions) => `${actions} ${actions === 1 ? 'action est' : 'actions sont'} en attente de votre part`,
  trackServiceCalls: 'Suivre mes interventions',
  newActivity: 'Nouvelles demandes',
  ongoingActivity: 'Demandes traitées le mois dernier',
  newClients: 'Nouveaux clients',
  verifiedClients: "Clients sur l'extranet",
  price: 'Prix',
  priceInputExample: 'à partir de XX €',
  tabContract: 'Contrat',
  tabQuote: 'Devis',
  tabInvoice: 'Facture',
  tabDocuments: 'Documents',
  tabContactInfo: 'Mes informations de contact',
  noQuoteAvailable: "Aucun devis n'est disponible.",
  noInvoiceAvailable: 'Pas de facture à afficher',
  downloadQuoteInvoice: (docType) => `Télécharger ${docType === 'quote' ? 'le devis' : 'la facture'}`,
  quote: (number) => `Devis ${number}`,
  invoice: (number) => `Facture ${number}`,
  pendingValidation: 'En cours de validation',
  quoteValidated: 'Devis validé',
  quoteSigned: 'Devis signé',
  interventionRdv: 'Rdv entretien',
  myContract: 'Mon contrat',
  clientRef: 'Référence client',
  renewalDate: 'Date de renouvellement',
  equipmentHeader: 'Équipements: ',
  lastInterventionHeader: 'Dernier entretien: ',
  documentsHeader: 'Documents: ',
  publishMyFigures: 'Publier mes chiffres',
  pleaseFillMonthlyFigures: 'Veuillez remplir vos chiffres clés pour accéder aux graphiques.',
  maintenance: 'Entretien',
  repair: 'Dépannage',
  contract: 'Contrat',
  bill: 'Facture',
  userSpace: 'Espace client',
  pendingPayment: 'En attente de règlement',
  payed: 'Factures réglées',
  payedWith: (mean) => `Réglée en ${mean}`,
  payedWhen: (when) => `Le ${when}`,
  quoteHelp: 'Une question sur un devis ?',
  noDocuments: "Aucun document n'est disponible.",
  contactContract: 'Sélectionner un contrat:*',
  contactTitle: 'Votre demande',
  contactTypeRepair: 'Dépannage',
  contactTypeRdv: 'RDV Entretien',
  contactTypeInfo: 'Informations',
  contactEditAddress: "modifier l'adresse",
  contactTextAreaPlaceholder: "Votre message, description de la panne et de l'équipement concerné.",
  contactAvailability: 'Mes disponibilités*',
  contactMorning: 'Matin',
  contactNoon: 'Midi',
  contactAfternoon: 'Après-midi',
  contactAllDay: 'Toute la journée',
  contactRGPD: "Les informations ci-dessus sont collectées pour permettre aux équipes d'Axenergie "
      + "de traiter votre demande et de prendre contact avec vous dans le cadre d'une potentielle intervention."
      + " Vous disposez d'un droit d'accès, de rectification et d'opposition, conformément à notre ",
  myPersonalInfo: 'Mes informations personnelles',
  editMyPersonalInfo: 'Modifier mes informations personnelles',
  modify: 'Modifier',
  demand: (interventionNb, isFinished) => `Demande N° ${interventionNb} - ${isFinished ? 'Traitée' : 'En cours'}`,
  demandFrom: 'Demande du',
  showIntervention: 'Voir le suivi',
  hideIntervention: 'Masquer le suivi',
  concernedEquipment: 'Equipement concerné',
  meeting: 'Rendez-vous',
  interventionReport: 'Compte rendu de l\'intervention',
  onlinePayment: 'Paiement en ligne',
  requestProcessed: 'Demande traitée',
  pageNotFound: 'Page non trouvée',
  notFoundDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.'
      + ' Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.',
  acceptedQuotation: 'Devis validé',
  refusedQuotation: 'Devis refusé',
  payedInvoice: 'Facture payée',
  payedInvoiceFromSite: 'Facture payée depuis la page agence',
  quotationNumber: 'Numéro du devis',
  invoiceNumber: 'Numéro de facture',
  quotationAmount: 'Montant du devis',
  invoiceAmount: 'Montant de la facture',
  seeQuotation: 'Voir le devis',
  seeInvoice: 'Voir la facture',
  theQuotation: 'le devis',
  theBill: 'la facture',
  welcome: 'Bienvenue',
  cancelledTransaction: 'Transaction annulée',
  cancelledTransactionSubtitle: 'Votre paiement a été annulé et ne sera pas pris en compte',
  backHome: 'Retourner à l\'accueil',
  successTransaction: 'Paiement accepté',
  successTransactionSubtitle: 'Votre facture a été payée avec succès',
  failedTransaction: 'Paiement refusé',
  failedTransactionSubtitle: 'Votre facture n\'a pas été payée.',
  paidThe: 'Payée le',
  mobilePhone: 'Téléphone mobile',
  landlinePhone: 'Téléphone fixe',
  changeContactInfo: 'Modification de coordonnées',
  newEmail: 'Nouvel e-mail',
  newMobilePhone: 'Nouveau téléphone mobile',
  newLandlinePhone: 'Nouveau téléphone fixe',
  rgpd: {
    login: 'Les informations ci-dessus sont nécessaires à votre authentification et votre connexion à l\'espace client.'
        + ' Vous disposez d\'un droit d\'accès, de rectification et d\'opposition, '
        + 'conformément à notre',
    forgotPassword: 'Les informations ci-dessus sont collectées afin de procéder à la '
        + 'réinitialisation de votre mot de passe. '
        + 'Vous disposez d\'un droit d\'accès, de rectification et d\'opposition, '
        + 'conformément à notre',
    footer: 'Les informations ci-dessus sont collectées pour permettre aux équipes d\'Axenergie de vous contacter, '
        + 'de réaliser un suivi et de vous accompagner dans les prestations de services proposées et souscrites. '
        + 'Vous disposez d\'un droit d\'accès, de rectification et d\'opposition, '
        + 'conformément à notre',
    privacyPolicy: 'politique de confidentialité',
  },
  footerCopyright: '© Copyright 2022 - Axenergie - Mentions légales',
  payplugApiKey: "Clef d'API PayPlug",
  downloadVoucher: 'Télécharger le bon d\'intervention',
  sitePayment: 'Activer le paiement en direct sur la page Agence',
  hideFromAdherentBook: 'Ne pas afficher sur le répertoire Région',
  unspecified: 'non renseigné',
  heatPump: 'Pompes à chaleur',
  heatPumpFull: 'Pompes à chaleur (Pac air, Pac air/eau, Pac clim)',
  renewContract: 'Renouvelez le contrat',
  signature: 'Signature',
  signatureTableHeader1: "Documents signés depuis l'intranet",
  signatureTableHeader2: "Documents signés depuis l'extranet",
  documentsToSign: 'Déposez ici vos documents à signer électroniquement',
  documentSubmission: 'Dépôt de document(s)',
  fillToSign: 'Veuillez remplir le formulaire pour signer vos documents :',
  fileToSign: 'Votre PDF à signer',
  addAnotherFile: 'Ajouter un autre PDF',
  signatureForm: {
    invalidForm: 'Le formulaire est invalide ou incomplet',
    success: 'Votre demande a bien été prise en compte',
    error: 'Une erreur est survenue',
    yousign_invalid_document: 'Le document soumis est invalide',
    yousign_obsolete_pdf_version: 'La version du PDF est trop ancienne pour être prise en charge. Veuillez fournir un fichier PDF en version 1.6 ou supérieure.',
  },
  monthlySummary: 'Récapitulatif du mois',
  signatureRecap: {
    headline: 'Vous avez consommé',
    baseline: 'signature(s) sur la période sélectionnée',
  },
  searchByName: 'Recherche par nom',
  searchByAdherent: 'Recherche par adherent',
  searchByAgency: 'Recherche par agence',
  searchByPeriod: 'Recherche par période',
  status: 'Statut',
  signed: 'Signé',
  toSign: 'En cours',
  failed: 'En échec',
  expiredContract: 'Expiré',
  declined: 'Refusé',
  signatureSeen: 'Document consulté',
  unknown: 'Inconnu',
  documents: 'Documents',
  quoteNumber: 'Numéro du devis',
  object: 'Objet',
  period: 'Période',
  signatureCount: 'Signatures consommées',
  totalSignatureCount: 'Total des signatures consommées :',
  summaryTable: 'Tableau récapitulatif',
  downloadArchiveName: 'signatures.zip',
  digitalSignature: 'Signer le devis',
  refuseSignature: 'Refuser le devis',
};
